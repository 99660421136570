export function initScrollTracking(steps, callback) {
  let ticking = false;
  let higestScrollPercentage = 0;
  const scrollSteps = [];

  steps.forEach(function (i) {
    scrollSteps.push({ min: i, fired: false });
  });

  let currentStep = 0;

  function getScrollPercentage() {
    const h = document.documentElement;
    const b = document.body;
    const st = 'scrollTop';
    const sh = 'scrollHeight';
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  }

  function scrolledAction() {
    if (getScrollPercentage() > higestScrollPercentage) {
      higestScrollPercentage = getScrollPercentage();
    }

    if (scrollSteps[currentStep]) {
      if (
        higestScrollPercentage >= scrollSteps[currentStep].min &&
        false === scrollSteps[currentStep].fired
      ) {
        scrollSteps[currentStep].fired = true;

        //fire callback
        if (callback && typeof callback === 'function') {
          callback(scrollSteps[currentStep].min);
        }
        currentStep += 1;
      }
    }
  }

  window.addEventListener('scroll', function () {
    if (!ticking) {
      window.requestAnimationFrame(function () {
        scrolledAction();
        ticking = false;
      });
    }
    ticking = true;
  });
}

export function initScrollTrackingRecepies(elements, callback) {
  const steps = [];
  let ticking = false;
  let currentStep = 0;

  elements.forEach(function (element) {
    const elementObject = document.querySelector(element.selector);
    steps.push(
      (element = {
        element: elementObject,
        name: element.name,
        scrolled: false,
      })
    );
  });

  function scrolledAction(positionScroll) {
    if (steps[currentStep]) {
      const elementBottom =
        steps[currentStep].element.getBoundingClientRect().bottom;

      if (
        positionScroll >= elementBottom &&
        false === steps[currentStep].scrolled
      ) {
        steps[currentStep].scrolled = true;

        //fire callback
        if (callback && typeof callback === 'function') {
          callback(steps[currentStep].name);
        }

        currentStep += 1;
      }
    }
  }

  window.addEventListener('scroll', function () {
    const scrollLastPosition = window.scrollY;
    if (!ticking) {
      window.requestAnimationFrame(function () {
        scrolledAction(scrollLastPosition);

        ticking = false;
      });
    }
    ticking = true;
  });
}
