import {
  initScrollTracking,
  initScrollTrackingRecepies,
} from './tracking.helpers';
const dataLayer = window.dataLayer || [];
const data = window.data || [];
//https://bitbucket.org/adviso/generic-sis-lassonde/

//5.1 UCS001 - Lassonde - Chargement de la page
dataLayer.push({
  event: 'PV',
  page: {
    content_group: data.content_group,
    page_language: data.language,
    page_title: document.title,
  },
  user: {
    user_industry: null, // Si non disponible, utiliser la valeur null.
    user_region: null, // Si non disponible, utiliser la valeur null.
  },
});

const formData = {};
//First step : store submited data
document.addEventListener('submit', function (event) {
  //using jQuery here, cause 'm a lazy boi
  jQuery.each(jQuery(event.target).serializeArray(), function () {
    formData[this.name] = this.value;
  });
});

//5.3 UCS003 - Lassonde - Inscription à un concours (Start)
jQuery(document).on('focusin.contest', 'form', function () {
  jQuery(document).off('focusin.contest');
  dataLayer.push({
    event: 'userAction',
    interaction: {
      event_name: 'generate_lead',
      lead_step: 'start', // Utiliser 'start' si l'utilisateur commence à remplir l'un des champs et 'submit' quand le formulaire est soumis avec succès.
      lead_type: 'contest form',
    },
    user: {
      user_industry: null, // Si non disponible, utiliser la valeur null.
      user_region: null, // Si non disponible, utiliser la valeur null.
    },
  });
});

jQuery(document).on('gform_confirmation_loaded', function (event) {
  dataLayer.push({
    event: 'userAction',
    interaction: {
      event_name: 'generate_lead',
      lead_step: 'submit', // Utiliser 'start' si l'utilisateur commence à remplir l'un des champs et 'submit' quand le formulaire est soumis avec succès.
      lead_type: 'contest form',
      contest_name: event.currentTarget.title,
    },
    user: {
      user_industry: null, // Si non disponible, utiliser la valeur null.
      user_region: null, // Si non disponible, utiliser la valeur null.
    },
  });
});

//click events
document.addEventListener('click', function (event) {
  //5.6 UCS006 - Lassonde - Clic vers les réseaux sociaux (Header)
  const socialMedia = event.target.closest('.site-header .social-media-button');
  if (socialMedia) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        interaction_location: 'header',
        click_type: 'social media click',
        link_text: socialMedia.title,
        link_url: socialMedia.href,
        social_media_name: socialMedia.querySelector('.sr-only').textContent, // Modifier en fonction du réseau social en question.
      },
    });
  }

  //5.6 UCS006 - Lassonde - Clic vers les réseaux sociaux (Pop-up)
  const socialMediaPopUp = event.target.closest(
    '.popup-form-wrapper .social-media-button'
  );
  if (socialMediaPopUp) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        interaction_location: 'popup',
        click_type: 'social media click',
        link_text: socialMediaPopUp.title,
        link_url: socialMediaPopUp.href,
        social_media_name:
          socialMediaPopUp.querySelector('.sr-only').textContent, // Modifier en fonction du réseau social en question.
      },
    });
  }

  //5.6 UCS006 - Lassonde - Clic vers les réseaux sociaux (Footer) - Les liens sont en fait dans les testimonials (partie en rose)
  const socialMediaFooter = event.target.closest(
    '.site-testimonials .social-media-button'
  );
  if (socialMediaFooter) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        interaction_location: 'footer',
        click_type: 'social media click',
        link_text: socialMediaFooter.title,
        link_url: socialMediaFooter.href,
        social_media_name:
          socialMediaFooter.querySelector('.sr-only').textContent, // Modifier en fonction du réseau social en question.
      },
    });
  }

  //5.7 UCS007 - Lassonde - Clic vers un partenaire commercial
  const productSupplier = event.target.closest('.product-supplier');
  if (productSupplier) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        click_type: 'commercial link',
        link_url: productSupplier.href,
      },
    });
  }

  //5.8 UCS008 - Lassonde - Clic sur un produit
  const link = event.target.closest('.product-item__inner');
  if (link) {
    const url = link.href;
    const isProduct = window.location.href.match(
      '/fr/nos-produits/|/our-products/'
    );
    if (isProduct) {
      dataLayer.push({
        event: 'userAction',
        interaction: {
          event_name: 'click',
          click_type: 'product click',
          link_text: link.textContent.replace(/\s+/g, ' ').trim(),
          link_url: url,
        },
        product: {
          product_category: link.dataset.productCategory,
          product_name: link.dataset.productName,
          product_format: link.dataset.productSize,
        },
      });
    }
  }

  //5.9 UCS009 - Lassonde - Clic sur un CTA
  const ctaBtn = event.target.closest('.cta-btn');
  if (ctaBtn) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        click_type: 'button click',
        link_text: ctaBtn.textContent.trim(), // Voir le tableau 'CTA Buttons' pour la sélection des boutons à intégrer dans ce Use Case.
        link_url: ctaBtn.href,
      },
    });
  }

  const ctaBtnTrackingClass = event.target.closest('.cta-tracking');
  if (ctaBtnTrackingClass) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        click_type: 'button click',
        link_text: ctaBtnTrackingClass.textContent.trim(), // Voir le tableau 'CTA Buttons' pour la sélection des boutons à intégrer dans ce Use Case.
        link_url: ctaBtnTrackingClass.querySelector('a').href,
      },
    });
  }

  const text = event.target.textContent.trim();
  const className = event.target.className;
  if (
    'nav-item__link' === className &&
    text.match('Où acheter?|Where to buy ?')
  ) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        click_type: 'button click',
        link_text: text, // Voir le tableau 'CTA Buttons' pour la sélection des boutons à intégrer dans ce Use Case.
        link_url: event.target.href,
      },
    });
  }

  //5.10 UCS010 - Lassonde - Lecture d'une vidéo - à revoir
  //pour le moment toujours dans GTM, vu la complexité de mise en œuvre

  //5.11 UCS011 - Lassonde - Partage d'un contenu
  const ShareBtn = event.target.closest('.social-button');
  if (ShareBtn) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'share',
        share_link: ShareBtn.href,
        share_destination: ShareBtn.textContent.replace(/\s+/g, ' ').trim(),
      },
    });
  }

  //5.15 UCS015 - Lassonde - Clic sur une recommandation de lecture
  const relatedContent = event.target.closest(
    '.related-recipes .recipe-item__inner, .blog-carousel__inner .post-item__inner'
  );
  if (relatedContent) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        click_type: 'content recommendation',
        link_url: relatedContent.href,
      },
    });
  }

  //5.18 UCS018 - Lassonde - Clic vers le site Carriere
  const carreerLink = event.target.closest('.footer-jobs .nav-item__link');
  if (carreerLink) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        click_type: 'career link',
        interaction_location: 'footer',
        link_url: carreerLink.href,
      },
    });
  }

  //5.19 UCS019 - Lassonde - Sortie vers la plateforme de réclamation de coupon Redemco
  const couponsLink = event.target.closest('.coupon-btn .wp-element-button');
  if (couponsLink) {
    dataLayer.push({
      event: 'userAction',
      interaction: {
        event_name: 'click',
        click_type: 'coupon link',
        link_url: couponsLink.href,
      },
    });
  }
});

document.addEventListener('DOMContentLoaded', () => {
  //5.12 UCS012 - Lassonde - Lecture d'un article
  if (window.location.href.match('/blogue/.*/|/blog/.*/')) {
    initScrollTracking([25, 50, 75, 100], function (step) {
      const readingTimeString = document
        .querySelector('.listing__item__reading-time')
        .textContent.trim();
      let readingTime = null;
      if (readingTimeString) {
        readingTime = readingTimeString.substr(
          0,
          readingTimeString.indexOf(' ')
        );
      }

      dataLayer.push({
        event: 'userAction',
        interaction: {
          event_name: 'scroll',
          scroll_progress: step + '%',
          reading_time: readingTime,
        },
      });
    });
  }

  //5.12 UCS012 - Lassonde - Lecture d'une recette
  if (window.location.href.match('/recettes/.*/|/recipes/.*/')) {
    //set elements you need to be tracked. event will be fired once bottom off element is entering in viewport
    initScrollTrackingRecepies(
      [
        { name: 'Ingredients', selector: '.info-ingredients' },
        { name: 'Preparation', selector: '.info-directions' },
        { name: 'Related Recipes', selector: '.related-recipes' },
      ],
      function (viewedElement) {
        dataLayer.push({
          event: 'userAction',
          interaction: {
            event_name: 'scroll',
            scroll_progress: viewedElement,
            reading_time: null,
          },
        });
      }
    );
  }
});
